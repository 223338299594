import React from "react";
import {
  AlertCard,
  AlertTitle,
  AlertText,
  ButtonCancel,
  ButtonConfirm,
  AlertAction,
  InLineLoader,
} from "./Alert.style";
import { styled } from "baseui";

type Props = {
  isOpen: boolean;
  onCancel: any;
  confirm?: boolean;
  onConfirm?: any;
  title: string;
  content: string;
  loadding?: boolean;
};

const Alert = (props: Props) => {
  const isIE =
    navigator.userAgent.indexOf("MSIE ") > -1 ||
    navigator.userAgent.indexOf("Trident/") > -1;
  return (
    <>
      {props.isOpen && (
        <AlertCard>
          <div style={{ height: "130px" }}>
            {!isIE ? (
              <AlertTitle style={{ width: "318px", wordBreak: "break-word" }}>
                {props.title}
              </AlertTitle>
            ) : (
                <AlertTitle style={{ width: "318px", wordBreak: "break-all" }}>
                  {props.title}
                </AlertTitle>
              )}

            <AlertText style={{ wordBreak: "keep-all", textAlign: "center" }}>
              {props.content}
            </AlertText>
            {props.loadding && <InLineLoader />}
          </div>
          <AlertAction>
            <ButtonCancel onClick={props.onCancel}>キャンセル</ButtonCancel>
            {props.confirm && (
              <ButtonConfirm onClick={props.onConfirm}>OK</ButtonConfirm>
            )}
          </AlertAction>
        </AlertCard>
      )}
    </>
  );
};

export default Alert;

export enum UserType {
  hanbaiten = 'hanbaiten',
  kojiten = 'kojiten',
}

export enum ExchangeGift {
  AMAZON_GIFT_CARD = 'AMAZON_GIFT_CARD',
  REAL_POINT_CARD = 'REAL_POINT_CARD',
}

export enum OrderStatus {
  pending = 'pending',
  approved = 'approved',
  confirmed = 'confirmed',
  canceled = 'canceled',
  waiting = 'waiting',
}

export enum OrderBuyerType {
  hanbaiten = 'hanbaiten',
  kojiten = 'kojiten',
  all = 'all',
}

export enum PointExchangeStatus {
  exchanged = 'exchanged',
  confirmed = 'confirmed',
  delivered = 'delivered',
}

export interface IMaker {
  id?: string
  name: string
  homepage: string
  sumCode: string
  hatCode: string
}
export interface ICategory {
  id?: string
  code: String
  name: String
  description?: String
}

export interface IImages {
  url: string
}

export interface IAddress {
  id?: string
  address: String
  default: Boolean
  lastUsed: Boolean
}

export interface IUser {
  id?: string
  username: string
  pwd: string
  userType: string
  nameKanji: string
  tel: string
  nameKana: string
  companyNameKanji: string
  companyNameKana: string
  receiverAddr?: string
  addresses?: [IAddress]
  sellerId?: string
  sellerId1?: string
  sellerId2?: string
  sellerId3?: string
  sellerId4?: string
  seller1?: any
  seller2?: any
  seller3?: any
  seller4?: any
  mailAddr: string
  addressDefault?: string
  rank?: string
  status?: string
  hatTeamCode?: string
  hatTokuCode?: string
  jobTitle?: any
  gender?: number
  visitorFlagDay1?: Boolean
  visitorFlagDay2?: Boolean
}
export interface IProduct {
  id?: string
  name: string
  description: string
  originalPrice: number
  numberItemsInSet: number
  hatSerialNumber?: string
  makerSerialNumber?: string
  hatCode5?: string
  sellingPrice?: number
  owner?: IUser
  maker?: IMaker
  category?: ICategory
  ownerId: string
  makerId: string
  categoryId: string
  images: IImages[]
}

export interface ICommon {
  userLogin: IUserLogin[]
  userLoginId: String
  showSidebar: boolean
}

export interface IUserLogin {
  id: String
  pwd: String
  username: String
  userType: String
  access_token: String
  expiresTime: String
}

export interface IUsers {
  user: IUser[]
}

export interface IStore {
  common: ICommon
  users: IUsers
}

export interface IOrder {
  id?: string
  deliveryAddress: string
  totalSellingPrice: number
  totalPoint: number
  totalPayAmount: number
  products: IProductOrder[]
  buyer?: IBuyer
  contact?: IContactOrder
  status?: string
}

export interface IBuyer {
  username: string
  userType: string
}

export interface IUserOrder {
  id?: string
  username: string
}

export interface IContactOrder {
  tel: string
  email: string
}

export interface IProductOrder {
  productId: string
  name: string
  originalPrice: number
  sellingPrice: number
  amount: number
  urlImage: string
}

export interface IPriceSettingProduct {
  id?: string
  inSalePercent: number
  point: number
  pointPercent: number
  productId?: string
  targetBuyerId?: string
}

import { useCreateContext } from './create-context';

const initialState = {
  isOpen: false,
  isOpen2: false,
  isOpenAnt: false,
  drawerComponent: null,
  drawerComponent2: null,
  drawerComponentAnt: null,
  data: null,
  data2: null,
  dataAnt: null,
};
type State = typeof initialState;
type Action = any;
function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return {
        ...state,
        isOpen: true,
        drawerComponent: action.drawerComponent,
        data: action.data,
      };
    case 'CLOSE_DRAWER':
      return {
        ...state,
        isOpen: false,
        drawerComponent: null,
        data: null,
      };
    case 'OPEN_DRAWER2':
      return {
        ...state,
        isOpen2: true,
        drawerComponent2: action.drawerComponent,
        data2: action.data,
      };
    case 'CLOSE_DRAWER2':
      return {
        ...state,
        isOpen2: false,
        drawerComponent2: null,
        data2: null,
      };
    case 'OPEN_DRAWER_ANT':
      return {
        ...state,
        isOpenAnt: true,
        drawerComponentAnt: action.drawerComponent,
        dataAnt: action.data,
      };
    case 'CLOSE_DRAWER_ANT':
      return {
        ...state,
        isOpenAnt: false,
        drawerComponentAnt: null,
        dataAnt: null,
      };
    default:
      return state;
  }
}


const [useDrawerState, useDrawerDispatch, DrawerProvider] = useCreateContext(
  initialState,
  reducer,
);

export { useDrawerState, useDrawerDispatch, DrawerProvider };
